import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import { appComponents } from '../components';
import userIsAuthenticated from '../utils/authentication';
import * as style from './styles.scss';
import FooterComponent from '../components/footer';
import { refreshCurrentActiveProgram, refreshUserPermissionWithoutRefresh, getHelpCentreDetails } from '../actions/userActions';

class WrapperView extends Component {
  componentDidUpdate(prevProp) {
    if (this.props.location.search !== prevProp.location.search) {
      window.scrollTo(0, 0);
    }
  }
  getModuleComponent() {
    const search = (new URLSearchParams(this.props.location.search));
    const roleURl = search.get('role');
    if (this.props.authenticated && !roleURl) {
      return () =>
        null;
    }
    const ChildComponent = this.props.authenticated ?
      userIsAuthenticated(this.props.component) : this.props.component;
    const userPermissions = this.props.currentActiveProgram && this.props.roleSelected ?
      _.filter(this.props.currentActiveProgram.permissions, p =>
        (p.action === 'view')) : undefined;
    const role = this.props.roleSelected;

    return childProps =>
      (<ChildComponent
        {...childProps}
        permissions={userPermissions}
        role={role}
        userDetail={this.props.userDetail}
        refreshCurrentActiveProgram={refreshCurrentActiveProgram}
        refreshUserPermissionWithoutRefresh={refreshUserPermissionWithoutRefresh}
        getHelpCentreDetails={getHelpCentreDetails}
      />);
  }

  render() {
    return (
      <Route exact={this.props.exact} path={this.props.path}>
        <div className="wrapper">
          {this.props.userDetail && this.props.numberModuleWillBeLoad === 0 ?
            <Route component={appComponents.Banner} /> :
            null}
          <div className="subwrapper">
            {this.props.navBar ? <Route component={appComponents.NavBar} /> : null}
            <div className={`main-content-panel ${style['main-container']}`}>
              <Route component={this.getModuleComponent()} />
            </div>
          </div>
          <footer>
            { !this.props.isPMI && this.props.userDetail
            && this.props.numberModuleWillBeLoad === 0 ?
              <FooterComponent /> : null
            }
          </footer>
        </div>
      </Route>
    );
  }
}
const mapStateToProps = state => ({
  userDetail: state.UserDetail.get('userDetail'),
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  roleSelected: state.UserDetail.get('roleSelected'),
  isPMI: state.LoginReducer.get('isPMI'),
  numberModuleWillBeLoad: state.ModuleLoader.get('numberModuleWillBeLoad'),
});

export default connect(
  mapStateToProps,
  null,
)(WrapperView);

WrapperView.propTypes = {
  navBar: PropTypes.bool,
  exact: PropTypes.bool,
  authenticated: PropTypes.bool,
  component: PropTypes.func.isRequired,
  path: PropTypes.string,
  userDetail: PropTypes.shape({}),
  currentActiveProgram: PropTypes.shape({
    permissions: PropTypes.shape({}),
    roleNames: PropTypes.shape(PropTypes.string),
  }),
  roleSelected: PropTypes.shape(),
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  isPMI: PropTypes.bool.isRequired,
  numberModuleWillBeLoad: PropTypes.number,
};

WrapperView.defaultProps = {
  navBar: false,
  exact: false,
  authenticated: false,
  path: undefined,
  userDetail: undefined,
  currentActiveProgram: undefined,
  roleSelected: undefined,
  numberModuleWillBeLoad: -1,
};
