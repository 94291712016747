import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  BrowserRouter,
  Switch,
  Route,
} from 'react-router-dom';
import { connect } from 'react-redux';
import {
  MessageBox,
  mapStateToProps as MessageBoxMapStateToProps,
  mapDispatchToProps as MessageBoxMapDispatchToProps,
} from '@vibrent/electryon';
import GlobalMessage from './coreModules/authentication/components/globalMessage';
import Views, { MainView, WrapperView, FullView } from './coreModules/authentication/views';
import { components as appComponents } from './coreModules/authentication/';
import getRouterKey from './coreModules/authentication/components/common/utils';

export const MessageBoxComponent = connect(
  MessageBoxMapStateToProps,
  MessageBoxMapDispatchToProps,
)(MessageBox);

/**
 * Router
 * @param {Object} props
 * @return {Element} React Element
 */
const Router = (props) => {
  let isHaveNoRoutes = false;
  let routes = [];
  // push all public module
  if (!props.userDetail) {
    props.modules.entrySeq().forEach((item) => {
      Object.keys(item[1].routes.pmiRoutes).forEach((key) => {
        const router = item[1].routes.pmiRoutes[key];
        // Check if permissions
        if (!router.permissions || router.permissions.length === 0) {
          // Then push WrapperView component
          const ModuleComponent = item[1].components[key];
          const View = router.view && Views[router.view] ? Views[router.view] : WrapperView;
          routes.push(<View
            path={router.path}
            exact
            component={ModuleComponent}
            navBar={false}
            authenticated={false}
          />);
        }
      });
    });
  }
  // Check if numberModuleWillBeLoad is 0
  if (props.currentActiveProgram && props.numberModuleWillBeLoad === 0) {
    const userPermissions =
      _.map(_.filter(props.currentActiveProgram.permissions, p =>
        (p.action === 'view')), p => p.resource);
    routes = [];
    const routerKey = getRouterKey(props.userDetail);
    props.modules.entrySeq().forEach((item) => {
      Object.keys(item[1].routes[routerKey]).forEach((key) => {
        const router = item[1].routes[routerKey][key];
        let havePermission = false;
        // Check if router have permission
        if (router.permissions && router.permissions.length > 0) {
          const permissionIntersection = _.intersection(router.permissions, userPermissions);
          havePermission = permissionIntersection.length > 0;
        } else {
          havePermission = true;
        }
        // Check if permissions
        if (havePermission) {
          // Then push WrapperView component
          const ModuleComponent = item[1].components[key];
          const View = router.view && Views[router.view] ? Views[router.view] : WrapperView;
          routes.push(<View
            path={router.path}
            exact
            component={ModuleComponent}
            navBar
            authenticated
          />);
        }
      });
    });
    isHaveNoRoutes = routes.length === 0;
  }
  const routesMC = [];
  if (props.userDetail) {
    routesMC.push(<WrapperView path="/changePassword" component={appComponents.ChangePassword} navBar authenticated />);
    routesMC.push(<WrapperView path="/managerAccount" component={appComponents.ManagerAccount} navBar authenticated />);
    if (!props.userDetail.pmiplatform) {
      routesMC.push(<WrapperView path="/profile" component={appComponents.UserProfile} navBar authenticated />);
      routesMC.push(<WrapperView path="/protocolBuilder" component={appComponents.ProtocolBuilder} navBar authenticated />);
    }
  }

  return (
    <BrowserRouter {...props} >
      <div>
        <Route component={appComponents.SessionRefresher} />
        <Switch>
          {routes}
          <FullView path="/" exact authenticated component={appComponents.EmptyDashboard} />
          <MainView path="/login" component={appComponents.Login} />
          <FullView path="/logout" component={appComponents.Logout} />
          {routesMC}
          <MainView path="/sessionExpired" component={appComponents.SessionExpired} />
          <MainView path="/accessDenied" component={appComponents.AccessDenied} />
          <MainView path="/noFeatureAccess" authenticated component={appComponents.NoFeatureAccess} />

          <MainView
            component={params => (<appComponents.NotFound
              {...params}
              isHaveNoRoutes={isHaveNoRoutes}
            />)}
            authenticated
          />
        </Switch>
        <MessageBoxComponent
          moduleName="Notification"
          id="notification-center"
          static
        />
        <Route component={GlobalMessage} />
      </div>
    </BrowserRouter>);
};

const mapStateToProps = state => ({
  modules: state.modules,
  numberModuleWillBeLoad: state.ModuleLoader.get('numberModuleWillBeLoad'),
  userDetail: state.UserDetail.get('userDetail'),
  currentActiveProgram: state.UserDetail.get('currentActiveProgram'),
  roleSelected: state.UserDetail.get('roleSelected'),
});

export default connect(
  mapStateToProps,
  null,
)(Router);

Router.propTypes = {
  modules: PropTypes.shape({
    entrySeq: PropTypes.func.isRequired,
  }),
  numberModuleWillBeLoad: PropTypes.number,
  userDetail: PropTypes.shape({
    pmiplatform: PropTypes.bool.isRequired,
  }),
  currentActiveProgram: PropTypes.shape({
    permissions: PropTypes.arrayOf(PropTypes.shape()),
    supportUser: PropTypes.bool,
  }),
  roleSelected: PropTypes.shape({
    displayName: PropTypes.string,
    name: PropTypes.string,
  }),
};

Router.defaultProps = {
  numberModuleWillBeLoad: -1,
  modules: [],
  userDetail: {
    pmiplatform: true,
  },
  currentActiveProgram: null,
  roleSelected: null,
};
